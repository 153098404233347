import { useVDR } from 'v-demi-request';
// import type { Ref } from 'vue';
// import type { AxiosHeaders } from 'axios';
import { baseUrl } from '~/utils/http/request';
import { useResponseHandler } from '~/utils/http/handler';

export const useRequests = () => {
    const { getRequest, postRequest } = useApis();
    const { handler, vdrHandler } = useResponseHandler();
    const { locale } = useTypedI18n();
    return {
        // *新增
        addByInfo: async (p: any) => {
            const res = await postRequest<any, any>(`${baseUrl}/mgbp/dealer/addDealer`, p, {});
            return await handler(res, {
                successMessage: true,
                // errMessage: false,
            });
        },
        // *修改
        modifyByInfo: async (p: any) => {
            const res = await postRequest<any>(`${baseUrl}/mgbp/dealer/modifyDealer`, p, {});
            return await handler(res, {
                successMessage: true,
                // errMessage: false,
            });
        },
        // *详情
        getInfoById: async (p: { dealerCode: string | number }) => {
            const res = await getRequest<{ dealerCode: string | number }>(
                `${baseUrl}/mgbp/dealer/getDealerInfoByCode`,
                p,
                {},
            );
            return await handler(res, {
                // successMessage: true,
                onSuccess: ({ res }) => {
                    return res as any;
                },
            });
        },
        // 字典集
        selectDictByArray: (codeTypeList: { codeTypeList: string }) => {
            const { onSuccess, send } = useVDR(
                () => `${baseUrl}/auth/dict/queryDictByCodeTypes`,
                (key: string) => getRequest<{ codeTypeList: string }>(key, codeTypeList),
                {
                    immediate: true,
                    requiredDeps: [locale],
                },
            );
            const { onSuccess: whenSuccess } = vdrHandler(onSuccess);
            return {
                whenSuccess,
                send,
            };
        },
        // 区域下拉框
        getRegionList: () => {
            const { onSuccess } = useVDR(
                () => `${baseUrl}/mgbp/getRegionList`,
                (key: string) => getRequest(key),
                {
                    // immediate: true,
                    requiredDeps: [locale],
                },
            );
            const selectTeam = ref<any>([]);
            onSuccess((param) => {
                handler(param, {
                    onSuccess: ({ res }) => {
                        selectTeam.value = res as any;
                    },
                });
            });
            return selectTeam;
        },
    };
};
