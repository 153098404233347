<template>
    <div ref="cardRef" class="drag-dialog">
        <n-card w-300 closable @close="dialogVisible = false">
            <template #header>
                <div @mousedown="mouseDrag">{{ dialogTitle }}</div>
            </template>
            <FormCreator
                v-model="formData"
                :cols="3"
                :form-list="formList"
                :rules="rules"
                :scope="validateScope"
            />
            <n-dynamic-input
                v-model:value="emailValue"
                :on-create="emailCreate"
                :item-style="{ width: '50%' }"
                :min="1"
                :max="99"
            >
                <template #default="{ value }">
                    <div style="display: flex; align-items: center; width: 100%">
                        <div style="margin-right: 10px; width: 160px; text-align: right">
                            <span style="color: red">*</span>
                            {{ $t('page.basic.dealer.dealer_email') }}
                        </div>
                        <n-input v-model:value="value.email" type="text" :status="value.status" />
                    </div>
                </template>
            </n-dynamic-input>
            <n-space justify="center" mt-20>
                <n-button v-if="dialogState !== 'detail'" type="primary" @click="debounceSave">
                    {{ $t('button.confirm') }}
                </n-button>
                <n-button @click="dialogVisible = false">{{ $t('button.cancel') }}</n-button>
            </n-space>
        </n-card>
    </div>
</template>

<script setup lang="ts">
import { FormCreator } from 'navuelidate';
// import type { VNodeRef } from 'vue';
import { useMessage } from 'naive-ui';
import { useDialog } from './dialogs';
import { useRequests } from './requests';
import type { dialogFormModel } from './type';
import { useDialogForm } from './form';
import { useUserStore } from '~/stores/user';
const emit = defineEmits(['success']);
const { t } = useTypedI18n();
const Message = useMessage();
const cardRef = ref(null) as any;
const emailValue = ref([{ email: '', status: 'success' }]);
const emailCreate = () => {
    return reactive({
        email: '',
        status: 'success',
    });
};
// const cardRef = ref<VNodeRef>() as any;
const userStore = useUserStore();
const { formData, formList, v$, validateScope, rules, focusing } = useDialogForm();
const { addByInfo, modifyByInfo, getInfoById } = useRequests();
const { dialogPropData, handleAfterEnter, dialogTitle, dialogVisible, dialogState } = useDialog();
const username = computed(() => userStore.userInfo?.username);
const dialogInfo = ref<Partial<dialogFormModel>>({});
const doSyncData = async () => {
    if (dialogState.value === 'info') {
        dialogInfo.value = (await getInfoById({
            dealerCode: username.value!,
        })) as dialogFormModel;
        dialogInfo.value.orgId = `${dialogInfo.value?.orgId}`;
        syncData(formData, dialogInfo);
        if (dialogInfo.value.dealerEmailList && dialogInfo.value.dealerEmailList.length > 0) {
            emailValue.value = dialogInfo.value.dealerEmailList.map((element) => {
                return { email: element, status: 'success' };
            });
        }
    }
};
handleAfterEnter.on(doSyncData);

const Save = async () => {
    const res = await v$.value.$validate();
    if (!res) return;
    let flag = true;
    emailValue.value.forEach((element: { email: string; status: string }) => {
        if (!flag) return;
        const regex = /^(([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4}))$/;
        if (!regex.test(element.email)) {
            element.status = 'error';
            flag = false;
        } else {
            element.status = 'success';
        }
    });
    if (!flag) {
        return Message.warning(t('validation.tip.mailbox_format_error'));
    } else {
        formData.value.dealerEmailList = emailValue.value.map((element) => {
            return element.email;
        });
    }
    const endForm = Object.assign({}, dialogInfo.value, { ...formData.value });
    let isSuccess: boolean | void = false;
    if (dialogState.value === 'info') {
        isSuccess = await modifyByInfo(endForm);
    } else {
        isSuccess = await addByInfo(endForm);
    }
    if (isSuccess) {
        emit('success');
        dialogVisible.value = false;
    }
};
watch(
    () => emailValue.value,
    () => {
        emailValue.value.forEach((element: { email: string; status: string }) => {
            const regex = /^(([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4}))$/;
            if (regex.test(element.email)) {
                element.status = 'success';
            }
        });
    },
    { deep: true },
);
const debounceSave = useDebounceFn(Save, 300);
const mouseDrag = (e: any) => {
    if (focusing.value) return;
    const offsetX = e.pageX - cardRef.value?.offsetLeft;
    const offsetY = e.pageY - cardRef.value?.offsetTop;
    document.onmousemove = function (d) {
        const left = d.pageX - offsetX;
        const top = d.pageY - offsetY;
        cardRef.value.style.left = `${left}px`;
        cardRef.value.style.top = `${top}px`;
    };
    // 鼠标抬起，清除鼠标移动事件
    document.onmouseup = function () {
        document.onmousemove = null;
    };
};
</script>

<style scoped>
.drag-dialog {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
</style>
