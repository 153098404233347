import { uuid } from 'vue3-uuid';
import { baseUrl } from '~/utils/http/request';
import { useResponseHandler } from '~/utils/http/handler';
export const useRequests = () => {
    const { handler } = useResponseHandler();
    const { postForm } = useApis();
    return {
        // 用户修改自己密码
        modifyPwd: async (p: {
            oldPassword: string | null;
            newPassword: string | null;
            confirmPassword: string | null;
        }) => {
            const res = await postForm<
                {
                    oldPassword: string | null;
                    newPassword: string | null;
                    confirmPassword: string | null;
                },
                boolean
            >(`${baseUrl}/auth/user/modifyPwd`, p,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'x-sequence-no': uuid.v4().replace(/\-/g, ''),
                    },
                },
            );
            return await handler(res, {
                successMessage: true,
            });
        },
    };
};
