<template>
    <n-space align="center" h-full>
        <n-dropdown :options="options" trigger="click" @select="handleSelect">
            <span style="color: #2080f0; cursor: pointer">Help</span>
        </n-dropdown>
        <!-- <n-ellipsis style="max-width: 100px">
            {{ username }}
            Help
        </n-ellipsis> -->
    </n-space>
    <ChangePsw :show="showChangePsw" @update:close="showChangePsw = false" />
    <n-modal v-bind="events" v-model:show="dialogVisible">
        <DealerInfo />
    </n-modal>
</template>

<script lang="ts" setup>
import type { DropdownOption } from 'naive-ui';
import { NIcon } from 'naive-ui';
import ChangePsw from './ChangePsw/ChangePsw.vue';
import DealerInfo from './DealerInfo/dialog.vue';
import { useDialog } from './DealerInfo/dialogs';
import { useDownloadTemplate } from '~/requests/common/common.request';
// import video from '~icons/carbon/video-player';
import CarbonDocumentAttachment from '~icons/carbon/document-attachment';
import { useUserStore } from '~/stores/user';
import { useThemeditorVisible } from '~/composables/useThemeEditor';

enum MenuKeys {
    logout = 'logout',
    showThemeEditor = 'showThemeEditor',
    Settings = 'Settings',
    videoDownload = 'videoDownload',
    documenDownload = 'documenDownload',
    ChangePsw = 'ChangePsw',
    DealerInfo = 'DealerInfo',
}

const showChangePsw = ref(false);
const showDealerInfo = ref(true);
const { dialogVisible, open, events } = useDialog();
const { t } = useTypedI18n();
const userStore = useUserStore();
const { downloadTemplate } = useDownloadTemplate();
const { showThemeEditor, localFlagShowThemeEditor } = useThemeditorVisible();
const actionMap = new Map<string, () => void | Promise<void>>();

const username = computed(() => userStore.userInfo?.username);
const portalUsername = computed(() => userStore.userInfo?.portalUsername);
const Dept = computed(() => userStore.userInfo?.dept);

const options: DropdownOption[] = [];
const { loading: downloadLoading, download } = downloadTemplate(
    'Dealer User Manual.pdf',
    '/mgbp/common/downloadMgbpExcelTemplate',
);
actionMap.set(MenuKeys.logout, userStore.logout);
actionMap.set(MenuKeys.showThemeEditor, () => {
    localFlagShowThemeEditor.value = !localFlagShowThemeEditor.value;
});
actionMap.set(MenuKeys.ChangePsw, () => {
    showChangePsw.value = true;
});
actionMap.set(MenuKeys.DealerInfo, () => {
    open({}, t('button.info'), {
        state: 'info',
    });
});
actionMap.set(MenuKeys.videoDownload, () => {
    console.log('videoDownload');
});
actionMap.set(MenuKeys.documenDownload, () => {
    console.log('documenDownload');
    download();
    // 下载操作手册
});
onMounted(() => {
    // if (Dept.value?.deptType !== '10081005') {
    options.push(
        // {
        //     label: () =>
        //         h('span', null, {
        //             default: () => t('button.video_download'),
        //         }),
        //     icon: () =>
        //         h(NIcon, null, {
        //             default: () => h(video),
        //         }),
        //     key: MenuKeys.videoDownload,
        // },
        {
            label: () =>
                h('span', null, {
                    default: () => t('button.documen_download'),
                }),
            icon: () =>
                h(NIcon, null, {
                    default: () => h(CarbonDocumentAttachment),
                }),
            key: MenuKeys.documenDownload,
        },
    );
    // }
});
const handleSelect = (key: string, option: DropdownOption) => {
    const action = actionMap.get(key);
    action && action();
};
</script>

<style scoped></style>
