import type { MaybeRefOrGetter } from '@vueuse/core';
import type { FormItemGiProps } from 'naive-ui';
import type { GetObjectOutput } from '@aws-sdk/client-s3';
import * as binconv from 'binconv';
import type { AxiosHeaders, RawAxiosRequestHeaders } from 'axios';
import { globalMessage } from './discreteApi';
import { i18nGlobal } from '~/utils/i18n';
import { useUserStore } from '~/stores/user';
export const getFileSize = (
    file?: File | null | number,
    unit: 'MB' | 'GB' | 'KB' = 'MB',
    fixed?: number,
) => {
    if (!file) return 0;
    let res = 0;
    const size = typeof file === 'number' ? file : file.size;
    if (unit === 'KB') res = ((size / 1024) * 100) / 100;
    if (unit === 'GB') res = ((size / 1024 / 1024 / 1024) * 100) / 100;
    if (unit === 'MB') res = ((size / 1024 / 1024) * 100) / 100;
    if (fixed) {
        const pow = 10 ** fixed;
        res = Math.round(res * pow) / pow;
    }
    return res;
};

// 根据文件名 是图片返回 true
export const canFileBePreviewedByName = (fileName: string) => {
    const regex = /[\s\S](\.gif|\.jpeg|\.png|\.jpg|\.bmp|\.webp)/i;
    return !(fileName.search(regex) === -1);
};

export function paramsFilter(params: { [key: string]: any }): Record<string, any> {
    const reqParam: { [key: string]: any } = {};
    for (const key in params) {
        if (params[key] || params[key] === 0) {
            reqParam[key] = params[key];
        }
    }
    return reqParam;
}

export const createDownload = (blobOrUrl: Blob | string, filename?: string) => {
    const elink = document.createElement('a');

    if ('download' in elink) {
        elink.download = filename || 'file';

        elink.style.display = 'none';

        elink.href = typeof blobOrUrl === 'string' ? blobOrUrl : URL.createObjectURL(blobOrUrl);

        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
    }
};

export function getFileNameFormHeader(headers: AxiosHeaders) {
    const header = (headers as RawAxiosRequestHeaders)['content-disposition'] as string;
    console.log(decodeURIComponent((header || '').split('filename=')[1]));
    return decodeURIComponent((header || '').split('filename=')[1]).replaceAll('"', '');
}

export function downloadCallback(response: Blob | any, filename?: string) {
    console.log(response);
    const isBlob = response instanceof Blob;
    let blob: Blob;
    console.log(isBlob, response, filename);
    if (isBlob) {
        blob = response as Blob;
        console.log(blob.size);
        if (blob.size === 0) return globalMessage.warning(i18nGlobal.t('export.noDataToExport'));
    } else {
        const res = response as Blob;
        if (!res) return globalMessage.warning(i18nGlobal.t('export.noDataToExport'));
        blob = res;
        console.log(res);
        if (res.size === 0) return globalMessage.warning(i18nGlobal.t('export.noDataToExport'));
    }

    createDownload(blob, filename);
}

export const getRecordIndex = (
    params: MaybeRefOrGetter<{ index: number; size: number; page: number }>,
) => {
    const { index, size, page } = resolveUnref(params);
    return (page - 1) * size + index + 1;
};

export type FilterNullConifg = {
    filterEmptyString?: boolean;
    deepClone?: boolean;
};
export type UnknownAnyKeyRecord = Record<string | number | symbol, unknown>;
export type UnknownCommonKeyRecord = Record<string | number, unknown>;

export const filterNull = <T extends UnknownAnyKeyRecord>(
    records: T,
    config: FilterNullConifg = {
        filterEmptyString: false,
        deepClone: false,
    },
) => {
    let copy: {} & T;
    if (Array.isArray(records)) copy = Object.assign([], records);
    else copy = Object.assign({}, records);
    const { filterEmptyString, deepClone } = config;
    let newObj: {} & T = copy;

    if (deepClone) newObj = structuredClone ? structuredClone(copy) : clone(copy, true);
    const keys = Reflect.ownKeys(newObj);
    for (const key of keys) {
        const val = Reflect.get(records, key);
        if (!val && val !== 0) {
            if (typeof val !== 'string') {
                Reflect.deleteProperty(records, key);
                continue;
            } else if (filterEmptyString && val === '') Reflect.deleteProperty(records, key);
            else if (!filterEmptyString && val === '') continue;
        }
    }

    return newObj as { [P in keyof T]: NonNullable<T[P]> };
};

export type SetLabelWidthByColumnRule = {
    labelWidth: number;
};

export const setLabelWidthByColumn = (
    list: { formItemGiProps: FormItemGiProps }[],
    rule: SetLabelWidthByColumnRule[],
) => {
    let column = 1;
    for (let i = 0; i <= list.length; i++) {
        const item = list[i];
        if (!item.formItemGiProps) item.formItemGiProps = {};
        item.formItemGiProps.labelWidth = rule[column - 1].labelWidth;
        column += 1;
        if (column > rule.length) column = 1;
    }
};
export type ls = {
    labelWidth: number;
    status: boolean;
};
export const useCont = (list, column: number, statusYi: ls, statusEr: ls, statusSan: ls) => {
    // console.log(statusYi, statusEr, statusSan);
    list.map((item, index) => {
        if (column % 2 === 0) {
            if ((index + 1) % 2 === 1 && statusYi.status) {
                item.formItemGiProps.labelWidth = statusYi.labelWidth;
            } else if ((index + 1) % 2 === 0 && statusEr.status) {
                item.formItemGiProps.labelWidth = statusEr.labelWidth;
            }
        } else if (column % 2 === 1) {
            if ((index + 1) % 3 === 1 && statusYi.status) {
                item.formItemGiProps.labelWidth = statusYi.labelWidth;
            } else if ((index + 1) % 3 === 2 && statusEr.status) {
                item.formItemGiProps.labelWidth = statusEr.labelWidth;
            } else if ((index + 1) % 3 === 0 && statusSan.status) {
                item.formItemGiProps.labelWidth = statusSan.labelWidth;
            }
        }
        return list;
    });
};
export const useContForm = (list, statusYi: ls, statusEr: ls, statusSan: ls, statusSi: ls) => {
    // console.log(statusYi, statusEr, statusSan);
    list.map((item, index) => {
        if ((index + 1) % 4 === 1 && statusYi.status) {
            item.formItemGiProps.labelWidth = statusYi.labelWidth;
        } else if ((index + 1) % 4 === 2 && statusEr.status) {
            item.formItemGiProps.labelWidth = statusEr.labelWidth;
        } else if ((index + 1) % 4 === 3 && statusSan.status) {
            item.formItemGiProps.labelWidth = statusSan.labelWidth;
        } else if ((index + 1) % 4 === 0 && statusSi.status) {
            item.formItemGiProps.labelWidth = statusSi.labelWidth;
        }
        return list;
    });
};

export const blobToImage = async (data: GetObjectOutput) => {
    const blob = await binconv.readableStreamToBlob(data.Body as ReadableStream);
    const newBlob = new Blob([blob], { type: data.ContentType });
    console.log(newBlob);
    const url = URL.createObjectURL(newBlob);
    return url;
};

export const chinese = /^[\u4E00-\u9FA5]$/;

export const getByteLength = (str: string) => {
    if (str.length === 0) {
        return 0;
    }
    const charList = str.split('');
    let l = 0;
    charList.forEach((el) => {
        chinese.test(el) ? (l += 3) : (l += 1);
    });
    return l;
};
// table列表校验权限显示不显示按钮
export const permission = (list: any, fn: any) => {
    console.log(list)
    const uerStore = useUserStore();
    try {
        let show = false;
        const permissions = uerStore.permissions || [];
        // console.log(permissions, 4343434, permissions.includes('*:*:*'))
        if (permissions.includes('*:*:*')) {
            show = true;
        }
        if (list === undefined) {
            return '';
        }
        if (Array.isArray(list)) {
            for (const code of list) {
                if (permissions.includes(code)) {
                    show = true;
                    break;
                }
            }
        } else {
            if (permissions.includes(list)) show = true;
        }
        if (show) {
            return fn
        } else {
            return ''
        }
    } catch (e) {
        console.warn('权限控制失败: ', e);
        return ''
    }
}
