export default {
    zhCN: {
        radio: {
            enabled: '启用',
            disabled: '停用',
            catalogue: '目录',
            individual: '个体',
            all: '全部',
            yes: '是',
            no: '否',
        },
        dropdown: {
            close_others: '关闭其他',
            close_right: '关闭右侧',
            close_left: '关闭左侧',
            copy_success: '复制成功',
            start_date: '开始日期',
            end_date: '结束日期',
        },
        tips: {
            copy_success: '复制成功',
            server_abnormal: '服务端异常，请稍后再试',
            unnamed: '未命名.xlsx',
            successful_export: '导出成功',
        },
        table: {
            No: '序号',
            action: '操作',
            action_btn: '操作',
            explain: '说明',
            fileName: '文件名',
            size: '大小(MB)',
            uploader: '上传人',
            uploadTime: '上传时间',
            system: '系统',
            detail: '明细',
            edit: '编辑',
            operator: '操作人',
            create_time: '创建时间',
            change_history: '日志',
            check: '审核',
            delete: '删除',
            appeal: '申诉',
            invoice: '发票',
            download: '下载',
        },
    },
    enUS: {
        radio: {
            enabled: 'Enabled',
            disabled: 'Disabled',
            catalogue: 'Catalogue',
            individual: 'Individual',
            all: 'All',
            tips: 'Tips',
            save: 'Save',
            yes: 'Yes',
            no: 'No',
        },
        dropdown: {
            close_others: 'Close Others',
            close_right: 'Close Right',
            close_left: 'Close Left',
            copy_success: 'Copy Success',
            start_date: 'Start Date',
            end_date: 'End Date',
        },
        tips: {
            copy_success: 'Copy Success',
            server_abnormal: 'The server is abnormal. Please try again later',
            unnamed: 'Unnamed.xlsx',
            successful_export: 'Successful export',
        },
        table: {
            No: 'No.',
            action: 'Operation',
            action_btn: 'Action',
            explain: 'Explain',
            fileName: 'File Name',
            size: 'Size(MB)',
            uploader: 'Uploader',
            uploadTime: 'Upload Time',
            system: 'System',
            detail: 'Detail',
            edit: 'Edit',
            operator: 'Operator',
            create_time: 'Create Time',
            change_history: 'LOG',
            check: 'Check',
            delete: 'Delete',
            appeal: 'Appeal',
            invoice: 'Invoice',
            download: 'Download',
        },
    },
};
