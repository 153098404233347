export default {
    zhCN: {
        document: '文档',
        name: '名称',
        sort: '排序',
        organization: '组',
        directory: '文档目录',
        parent_directory: '上级目录',
        new_directory: '新建目录',
        edit_directory: '编辑目录',
        primary_directory: '一级目录',
        document_title: '文档标题',
        vehicle_platform: '车型平台',
        status: '状态',
        release_status: '发布状态',
        create_time: '创建时间',
        secondary_directory: '二级目录',
        model: '车型',
        attachment_name: '附件名称',
        publishing_department: '发布部门',
        publisher: '发布人',
        person_in_charge: '负责人',
        remarks: '备注',
        publish_objects: '发布对象',
        publish_mode: '发布模式',
        whether_the_document_is_downloadable: '文档是否可下载',
        document_information: '文档信息',
        document_tag: '文档标签',
        operation_record: '操作记录',
        operation_details: '操作明细',
        operating_time: '操作时间',
        operator: '操作人',
        to_be_released: '待发布',
        published: '已发布',
        voided: '已作废',
        remark: '备注',
        files_upload_tip: '点击或者拖动文件到该区域来上传',
    },
    enUS: {
        document: 'Document',
        name: 'Name',
        sort: 'Sort',
        directory: 'Directory',
        organization: 'Organization',
        parent_directory: 'Parent directory',
        new_directory: 'New directory',
        edit_directory: 'Edit directory',
        primary_directory: 'Primary directory',
        document_title: 'Document title',
        vehicle_platform: 'Vehicle platform',
        status: 'Status',
        release_status: 'Release status',
        create_time: 'Create time',
        secondary_directory: 'Secondary directory',
        model: 'Model',
        attachment_name: 'File name',
        publishing_department: 'Publishing department',
        publisher: 'Publisher',
        person_in_charge: 'Person in charge',
        remarks: 'Remarks',
        publish_objects: 'Release to',
        publish_mode: 'Release mode',
        whether_the_document_is_downloadable: 'Whether the document is downloadable',
        document_information: 'Document information',
        document_tag: 'Document tag',
        operation_record: 'Operation record',
        operation_details: 'Operation details',
        operating_time: 'Operating time',
        operator: 'Operator',
        to_be_released: 'To be released',
        published: 'Released',
        voided: 'Invalid',
        remark: 'Remark',
        files_upload_tip: 'Click or drag the file into this area to upload it',
    },
};
