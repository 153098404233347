import { FormType } from 'navuelidate';
import { helpers } from '@vuelidate/validators';
import type { SelectOption } from 'naive-ui';
import { NTooltip } from 'naive-ui';
import type { dialogFormModel } from './type';
import { useRequests } from './requests';
import { useDialog } from './dialogs';

export const useSelectTeam = () => {
    const { selectDictByArray } = useRequests();
    const businessStatusTeam = ref<any>([]);
    const { whenSuccess } = selectDictByArray({ codeTypeList: '3009' });
    whenSuccess((res) => {
        if (res.res) {
            if (res.res[3009]) {
                businessStatusTeam.value = res.res[3009].map((c: { codeName: any; code: any }) => {
                    return { label: c.codeName, value: c.code };
                });
            }
        }
    });
    return {
        businessStatusTeam,
    };
};

export const useDialogForm = () => {
    const { t } = useTypedI18n();
    const { dialogState } = useDialog();
    const defaultData = {
        dealerCode: '',
        dealerName: '',
        dealerSapno: '',
        // dealerEmail: '',
        kpiCalculationType: '',
        dealerGroup: '',
        dealerContactPerson: '',
        orgId: null,
        dealerBusinessStatus: null,
        dealerLevel: null,
        romName: '',
        romEmail: '',
        dealerVendorNumber: null,
        terminatedDate: null,
        isDeliveryRefund: null,
    } as dialogFormModel;
    const validateScope = Symbol('basicDealerDialogForm');
    const { getRegionList, selectDictByArray } = useRequests();
    const getRegionTeam = getRegionList();
    const levelTeam = ref<any>([]);
    const calculationModeTeam = ref<any>([]);
    const { whenSuccess } = selectDictByArray({ codeTypeList: '6004,6005' });
    whenSuccess((res) => {
        if (res.res) {
            if (res.res[6004]) {
                levelTeam.value = res.res[6004].map(
                    (c: { codeName: any; code: any; info: any }) => {
                        if (c.code === 'DEALER_LEVEL_ONE') {
                            c.info = 'Level 1: Annual sales volume < 200';
                        } else if (c.code === 'DEALER_LEVEL_TWO') {
                            c.info =
                                'Level 2: Annual sales volume >=200 & Annual sales volume < 300';
                        } else if (c.code === 'DEALER_LEVEL_THREE') {
                            c.info = 'Level 3 Annual sales volume > 300';
                        }
                        return { label: c.codeName, value: c.code, info: c.info };
                    },
                );
            }
            if (res.res[6005]) {
                calculationModeTeam.value = res.res[6005].map((c: { codeName: any; code: any }) => {
                    return { label: c.codeName, value: c.code };
                });
            }
        }
    });
    const { businessStatusTeam } = useSelectTeam();
    const focusing = ref(false);
    const { required } = useVuelidateRules();
    let rules = reactive<any>({});
    const { createFormListItem, v$, formData } = useFormCreator({
        scope: validateScope,
        defaultData,
        rules,
        globalFormItemGiProps: {
            labelWidth: 130,
            labelAlign: 'right',
            size: 'small',
        },
        globalProps: () => {
            return {
                onFocus: () => {
                    focusing.value = true;
                },
                onBlur: () => {
                    focusing.value = false;
                },
            };
        },
    });

    const mustBeEmail = helpers.withMessage(
        t('validation.tip.mailbox_format_error'),
        helpers.regex(
            /^((([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})([-_,，& ]?))+)$/,
        ),
    );
    const mustBeDate = helpers.withMessage(
        t('validation.tip.date_format_error'),
        helpers.regex(
            /^(((((0?[1-9])|((1|2)[0-9])|30|31)[/])?)(0?[1-9]|1[0-2])[/]((?!0000)[0-9]{4}))$/,
        ),
    );
    const mustBeGroup = helpers.withMessage(t('page.basic.dealer.must_be_group'), () => {
        if (formData.value.kpiCalculationType === 'DEALER_KPI_GROUP') {
            if (formData.value.dealerGroup) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    });
    rules = {
        dealerCode: { required },
        dealerName: { required },
        kpiCalculationType: { required },
        dealerGroup: { mustBeGroup },
        orgId: { required },
        dealerBusinessStatus: { required },
        romEmail: { mustBeEmail },
        // dealerEmail: { required, mustBeEmail },
        terminatedDate: { mustBeDate },
    };
    const formList = computed(() => {
        return [
            createFormListItem(
                {
                    key: 'dealerCode',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.dealer_code'),
                    props: {
                        disabled: dialogState.value === 'info',
                        // readonly: dialogState.value === 'edit',
                        maxlength: 50,
                        placeholder: dialogState.value === 'info' ? '' : t('select.input'),
                    },
                    formItemGiProps: {
                        // showRequireMark: dialogState.value === 'edit',
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'dealerName',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.dealer_name'),
                    props: {
                        maxlength: 50,
                        disabled: dialogState.value === 'info',
                        // readonly: dialogState.value === 'edit',
                        placeholder: dialogState.value === 'info' ? '' : t('select.input'),
                    },
                    formItemGiProps: {
                        // showRequireMark: dialogState.value === 'edit',
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'dealerSapno',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.dealer_sap_no'),
                    props: {
                        maxlength: 50,
                        disabled: dialogState.value === 'info',
                        // readonly: dialogState.value === 'edit',
                        placeholder: dialogState.value === 'info' ? '' : t('select.input'),
                    },
                    formItemGiProps: {
                        // showRequireMark: true,
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'dealerVendorNumber',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.vendor_number'),
                    props: {
                        maxlength: 50,
                        disabled: dialogState.value === 'info',
                        // readonly: dialogState.value === 'edit',
                        placeholder: dialogState.value === 'info' ? '' : t('select.input'),
                    },
                    formItemGiProps: {
                        // showRequireMark: true,
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'kpiCalculationType',
                    formType: FormType.Select,
                },
                {
                    label: t('page.basic.dealer.calculation_mode'),
                    props: {
                        options: calculationModeTeam.value || [],
                        placeholder: dialogState.value === 'info' ? '' : t('select.select'),
                        clearable: true,
                        // labelField: 'label',
                        // valueField: 'key',
                        filterable: true,
                        disabled: dialogState.value === 'info',
                        // onUpdateValue: (val) => {},
                    },
                    formItemGiProps: {
                        labelWidth: 125,
                        showRequireMark: dialogState.value === 'edit',
                        requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'dealerGroup',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.group'),
                    props: {
                        maxlength: 50,
                        disabled:
                            dialogState.value === 'info' ||
                            formData.value.kpiCalculationType === 'DEALER_KPI_SINGLE',
                        placeholder: dialogState.value === 'info' ? '' : t('select.input'),
                    },
                    formItemGiProps: {
                        showRequireMark:
                            formData.value.kpiCalculationType === 'DEALER_KPI_GROUP' &&
                            dialogState.value === 'edit',
                        requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'dealerContactPerson',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.contact_person'),
                    props: {
                        maxlength: 50,
                        disabled: dialogState.value === 'info',
                        placeholder: dialogState.value === 'info' ? '' : t('select.input'),
                    },
                    formItemGiProps: {
                        // showRequireMark: true,
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'dealerBusinessStatus',
                    formType: FormType.Select,
                },
                {
                    label: t('page.basic.dealer.business_status'),
                    props: {
                        options: businessStatusTeam.value || [],
                        placeholder: dialogState.value === 'info' ? '' : t('select.select'),
                        clearable: true,
                        // labelField: 'label',
                        // valueField: 'key',
                        filterable: true,
                        disabled: dialogState.value === 'info',
                        // onUpdateValue: (val) => {},
                    },
                    formItemGiProps: {
                        showRequireMark: dialogState.value === 'edit',
                        requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'dealerLevel',
                    formType: FormType.Select,
                },
                {
                    label: t('page.basic.dealer.dealer_level'),
                    props: {
                        options: levelTeam.value || [],
                        placeholder: dialogState.value === 'info' ? '' : t('select.select'),
                        clearable: true,
                        // labelField: 'selectValue',
                        // valueField: 'selectKey',
                        filterable: true,
                        disabled: dialogState.value === 'info',
                        renderOption: ({ node, option }: { node: VNode; option: SelectOption }) =>
                            h(NTooltip, null, {
                                trigger: () => node,
                                default: () => option.info,
                            }),
                        // onUpdateValue: (val) => {},
                    },
                    formItemGiProps: {
                        // showRequireMark: true,
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'orgId',
                    formType: FormType.Select,
                },
                {
                    label: t('page.basic.dealer.area'),
                    props: {
                        options: getRegionTeam.value || [],
                        placeholder: dialogState.value === 'info' ? '' : t('select.select'),
                        clearable: true,
                        labelField: 'selectValue',
                        valueField: 'selectKey',
                        filterable: true,
                        disabled: dialogState.value === 'info',
                        // onUpdateValue: (val) => {},
                    },
                    formItemGiProps: {
                        showRequireMark: dialogState.value === 'edit',
                        requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'romName',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.rom'),
                    props: {
                        maxlength: 50,
                        disabled: dialogState.value === 'info',
                        // readonly: dialogState.value === 'edit',
                        placeholder: '',
                    },
                    formItemGiProps: {
                        // showRequireMark: true,
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'romEmail',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.rom_email'),
                    props: {
                        maxlength: 50,
                        disabled: dialogState.value === 'info',
                        // readonly: dialogState.value === 'edit',
                        placeholder: '',
                    },
                    formItemGiProps: {
                        // showRequireMark: dialogState.value === 'edit',
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'terminatedDate',
                    formType: FormType.Input,
                },
                {
                    label: t('page.basic.dealer.terminatedDate'),
                    props: {
                        maxlength: 50,
                        disabled: dialogState.value === 'info',
                        placeholder: dialogState.value === 'info' ? '' : t('select.input'),
                    },
                    formItemGiProps: {
                        // showRequireMark: true,
                        // requireMarkPlacement: 'left',
                        labelAlign: 'right',
                    },
                },
            ),
            createFormListItem(
                {
                    key: 'isDeliveryRefund',
                    formType: FormType.RadioGroup,
                },
                {
                    props: {
                        disabled: dialogState.value === 'info',
                    },
                    formItemGiProps: {
                        labelAlign: 'right',
                    },
                    options: [
                        { label: t('button.yes'), value: 'Y' },
                        { label: t('button.deny'), value: 'N' },
                    ],
                    label: t('page.basic.dealer.is_delivery_refund'),
                },
            ),
            // email
            // createFormListItem(
            //     {
            //         key: 'dealerEmail',
            //         formType: FormType.Input,
            //     },
            //     {
            //         label: t('page.basic.dealer.dealer_email'),
            //         props: {
            //             maxlength: 50,
            //             disabled: dialogState.value === 'info',
            //             placeholder: dialogState.value === 'info' ? '' : t('select.input'),
            //         },
            //         formItemGiProps: {
            //             span: 2,
            //             showRequireMark: dialogState.value === 'edit',
            //             requireMarkPlacement: 'left',
            //             labelAlign: 'right',
            //         },
            //     },
            // ),
        ];
    });

    return {
        formList,
        formData,
        v$,
        validateScope,
        rules,
        focusing,
    };
};
