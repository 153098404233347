import { useMessage } from 'naive-ui';
import { baseUrl, postRequest } from '~/utils/http/request';
import { useResponseHandler } from '~/utils/http/handler';

export const useRequests = () => {
    const message = useMessage();
    const { getRequest } = useApis();
    const { handler, vdrHandler } = useResponseHandler();
    const { locale, t } = useTypedI18n();

    return {

        // 切换国家
        switchCompanyCountry: async (p: any) => {
            const res = await postRequest(
                `${baseUrl}/auth/switchCompanyCountry`,
                p,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                },
            );
            console.log('删除res', res)
            return await handler(res, {
                successMessage: true,
            });
        },
    }
};
