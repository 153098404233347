export default {
    menu: {
        zhCN: {
            menu: '菜单',
            menu_name: '菜单名称',
            button_name: '按钮名称',
            purview: '权限编码',
            menu_type: '菜单类型',
            menu_new: '菜单新增',
            menu_edit: '菜单编辑',
            icon: '图标',
            parent: '父级菜单',
            order_number: '排序',
            path: '路由地址',
            status: '菜单状态',
        },
        enUS: {
            menu: 'Menu',
            menu_name: 'Menu Name',
            button_name: 'Button Name',
            purview: 'Purview Code',
            menu_type: 'Menu Type',
            menu_new: 'New Menu',
            menu_edit: 'Modify Menu',
            icon: 'Icon',
            parent: 'Parent',
            order_number: 'Order Number',
            path: 'Path',
            status: 'Status',
        },
    },
    dict: {
        zhCN: {
            dictionary_name: '字典名称',
            creater: '创建人',
            dictionary_code: '字典代码',
            code_type: '类型代码',
            code_type_name: '类型名称',
            creation_time: '创建时间',
            dict_new: '字典新增',
            dict_edit: '字典编辑',
            lang: '语言',
            sort: '排序',
        },
        enUS: {
            dictionary_name: 'Dictionary Name',
            creater: 'Creater',
            dictionary_code: 'Dictionary Code',
            code_type: 'Code Type',
            code_type_name: 'Code Type Name',
            creation_time: 'Creation Time',
            dict_new: 'Dict New',
            dict_edit: 'Dict Edit',
            lang: 'Lang',
            sort: 'Sort',
        },
    },
    dept: {
        zhCN: {
            system_selection: '系统选择',
            parent_dept: '上级部门',
            dept_name: '组',
            currency: '货币',
            currency_symbol: '货币符号',
            tax_rates: '税率',
            time_zone: '时区',
            dept_code: '组织编码',
            leader: '负责人',
            email: '邮箱',
            create_by: '创建人',
            create_date: '创建时间',
            organization_editing: '组织编辑',
            phone: '电话',
            organizational_type: '组织类型',
            thousands_separator: '千位分隔符',
        },
        enUS: {
            system_selection: 'System selection',
            organizational_type: 'Organizational type',
            parent_dept: 'Parent dept',
            currency: 'Currency',
            currency_symbol: 'Currency symbol',
            tax_rates: 'Tax Rates',
            time_zone: 'Time Zone',
            dept_name: 'Dept Name',
            dept_code: 'Dept Code',
            leader: 'Leader',
            email: 'Email',
            create_by: 'Create By',
            create_date: 'Create Date',
            organization_editing: 'Organization Editing',
            phone: 'Phone',
            thousands_separator: 'Thousands Separator',
        },
    },
    role: {
        zhCN: {
            menu_maintenance: '菜单维护',
            role_name: '角色名称',
            role_key: '角色代码',
            role_id: '显示顺序',
            status: '状态',
            create_date: '创建时间',
            role_sort: '排序',
            role_new: '角色新增',
            role_edit: '角色编辑',
            role_bleak: '菜单',
        },
        enUS: {
            menu_maintenance: 'Menu',
            role_name: 'Role Name',
            role_key: 'Role Code',
            role_id: 'Display Order',
            status: 'status',
            create_date: 'Create Time',
            role_sort: 'Sort',
            role_new: 'New Role',
            role_edit: 'Edit Role',
            role_bleak: 'Bleak',
        },
    },
    user: {
        zhCN: {
            serial_no: '序号',
            username: ' 用户名',
            name: '姓名',
            sex: '性别',
            phone_number: '手机号',
            email: '邮箱',
            role: '角色',
            login_time: '启用状态',
            password: '密码',
            user_edit: '用户编辑',
        },
        enUS: {
            serial_no: 'No',
            username: ' Username',
            name: 'Name',
            sex: 'gender',
            phone_number: 'Phone Number',
            email: 'email',
            role: 'Role',
            login_time: 'Enable Status',
            password: 'Password',
            user_edit: 'User Edit',
        },
    },
};
