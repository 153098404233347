import button from './button';
import validation from './validation';
import table from './table';
import auth from './auth';
export default {
    button,
    validation,
    table,
    auth,
};
