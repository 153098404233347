export enum ButtonStatus {
    Modify = 'modify',
    Add = 'add',
}
export const useDialog = createGlobalState(() => {
    const { t } = useTypedI18n();
    const {
        dialogVisible,
        dialogPropData,
        open,
        events,
        handleAfterLeave,
        handleAfterEnter,
        dialogTitle,
        dialogState,
        dialogLoading,
    } = useDialogStates<any>({
        title: t('button.info'),
    });

    return {
        dialogVisible,
        dialogPropData,
        open,
        events,
        handleAfterLeave,
        handleAfterEnter,
        dialogTitle,
        dialogState,
        dialogLoading,
    };
});
