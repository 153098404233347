

import __layout_0 from '/data/project/smil-mgbp-frontend/src/layouts/default.vue'
export const layouts = {
'auth': () => import('/data/project/smil-mgbp-frontend/src/layouts/auth.vue'),
'default': __layout_0,
'home': () => import('/data/project/smil-mgbp-frontend/src/layouts/home.vue'),
'default-modules/routeSearch': () => import('/data/project/smil-mgbp-frontend/src/layouts/default-modules/routeSearch.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
