export default {
    zhCN: {
        auth: {
            login: '登录',
            username: '用户名',
            username_placeholder: '请输入用户名',
            password: '密码',
            password_placeholder: '请输入密码',
            code: '验证码',
            code_placeholder: '请输入验证码',
            logout: '注销',
            logout_success: '注销成功',
            day: '天',
            password_expiration: '密码已到期请修改',
            expire: '距离密码到期还剩',
            conseils: '必须包含大写字母、小写字母、数字、特殊符号（{msg}），密码长度在 8-16 位',
        },
        confirm: {
            confirm_deletion: '确认删除?',
            confirm_void: '确认作废?',
            confirm_release: '确认发布?',
            confirm_reject: '确认拒绝?',
            ack: '确认',
            cancel: '取消',
            confirm: '是否确认？',
            non_records: '请至少选择一条数据',
            pass: '确认提交审核',
            pass_apply: '请确认您要提交上诉申请！(只有 2 次机会）！',
            pass_apply_red: '如上诉时价格条款有误，请在DCS中选择正确的价格条款以完成上诉。',
            pass_apply_red2: '请在上诉前确认!',
            recalculate: '确认重新计算',
            confirm_switch_country: '确认切换国家?',
        },
        request: {
            failed_placeholder: '请求失败，服务端错误',
            success_placeholder: '请求成功',
            auth: {
                login_success: '登录成功',
            },
            error: {
                '404': '请求路径不存在',
                '403': '权限不足',
            },
        },
    },
    enUS: {
        auth: {
            login: 'Login',
            username: 'Username',
            username_placeholder: 'Enter username',
            password: 'Password',
            password_placeholder: 'Enter password',
            code: 'Verify Code',
            code_placeholder: 'Enter verify code',
            logout: 'Logout',
            logout_success: 'Logout success',
            day: 'Day',
            password_expiration: 'The password has expired, please modify it',
            expire: 'Remaining until password expiration',
            conseils: 'The password must contain uppercase letters, lowercase letters, numbers, and special characters ({msg}), and the length of the password should be between 8 to 16 characters',
        },
        confirm: {
            confirm_deletion: 'Confirm deletion?',
            confirm_void: 'Confirm void?',
            confirm_release: 'Confirm release?',
            confirm_reject: 'Confirm Reject?',
            ack: 'Confirm',
            cancel: 'Cancel',
            confirm: 'Do you confirm the reconciliation amount?',
            confirm1: 'Please ensure that the calculation is started!',
            non_records: 'Please choose at least one record',
            pass: 'Confirm submission for review',
            pass_apply: 'Please confirm that you want to submit an appeal apply! (Only have 2 chances)!',
            pass_apply_red: 'If appeal with Price Term Error please select the correct Price Term in DCS to get the appealing completed.',
            pass_apply_red2: 'Please make sure you confirm before any appeal!',
            recalculate: 'Confirm Recalculate',
            confirm_switch_country: 'Confirm Switch Country?',
        },
        request: {
            failed_placeholder: 'Request failed, backend error',
            success_placeholder: 'Request success',
            auth: {
                login_success: 'Login success',
            },
            error: {
                '404': 'Request url not found',
                '403': 'Permission denied',
            },
        },
    },
};
