export default {
    rebate: {
        zhCN: {
            full_screen: '全屏',
            menu: '菜单',
            model: 'Model',
            private_with_finance_test_drive: 'Private with finance & Test Drive',
            where_applicable: '(where applicable)',
            cash_in_lieu_test_drive: 'Cash in Lieu & Test Drive',
            demo: 'Demo',
            courtesy_car_programme: 'Courtesy Car Programme',
            motability: 'Motability',
            dealer_fleet: 'Dealer Fleet',
            total: '合计',
            menu_name: '菜单名称',
            button_name: '按钮名称',
            purview: '权限编码',
            menu_type: '菜单类型',
            menu_new: '菜单新增',
            menu_edit: '菜单编辑',
            icon: '图标',
            parent: '父级菜单',
            order_number: '排序',
            path: '路由地址',
            status: '菜单状态',
            time: 'Time',
            current: 'Current',
            yoy: 'YOY',
            qoq: 'QOQ',
        },
        enUS: {
            full_screen: 'Full Screen',
            menu: 'Menu',
            model: 'Model',
            private_with_finance_test_drive: 'Private with finance & Test Drive',
            where_applicable: '(where applicable)',
            cash_in_lieu_test_drive: 'Cash in Lieu & Test Drive',
            demo: 'Demo',
            courtesy_car_programme: 'Courtesy Car Programme',
            motability: 'Motability',
            dealer_fleet: 'Dealer Fleet',
            total: 'Total',
            menu_name: 'Menu Name',
            button_name: 'Button Name',
            purview: 'Purview Code',
            menu_type: 'Menu Type',
            menu_new: 'New Menu',
            menu_edit: 'Modify Menu',
            icon: 'Icon',
            parent: 'Parent',
            order_number: 'Order Number',
            path: 'Path',
            status: 'Status',
            time: 'Time',
            current: 'Current',
            yoy: 'YOY',
            qoq: 'QOQ',
        },
    },
    statistics: {
        zhCN: {
            type: '类型',
            time: '时间',
            start_time: '开始时间',
            end_time: '结束时间',
            date: '时间',
            dealer_code: '经销商代码',
            code_type: '代码类型',
            dealer_name: '经销商名字',
            dealer_level: '经销商等级',
            model: '车型',
            volume: '销量',
        },
        enUS: {
            type: 'Type',
            time: 'Time',
            start_time: 'Start Time',
            end_time: 'End Time',
            dealer_code: 'Dealer Code',
            code_type: 'Code Type',
            dealer_name: 'Dealer Name',
            dealer_level: 'Dealer Level',
            model: 'Model',
            volume: 'Volume',
        },
    },
    santander: {
        zhCN: {
            reconciliation_date: '对账日期/文件',
            credit_company: '信贷公司',
            santander_total_amount: '对账公司总金额',
            MGBP_total_amount: 'MGBP Total Amount',
            amount_of_variance: '差额',
            rebate_type: '返利类型',
            reconciliation_status: '对账状态',
            create_time: '更新时间',
            start_time: '开始时间',
            end_time: '结束时间',
            dealer_code: '经销商代码',
            dealer_name: '经销商名称',
            payment_type: '支付类型',
            status: '状态',
            basic_price: '基础价格',
            paint: '喷漆',
            delivery: '交付',
            total_price: '订单总金额',
            pda: 'FDA',
            credit_company_fleet: 'Credit Company Fleet',
            total_support: 'Total Support',
            credit_company_input_subsidy: 'Credit Company Input Subsidy',
            credit_company_input_fda: 'Credit Company Input FDA',
            credit_company_input_fleet: 'Credit Company Input Fleet',
            credit_company_total_support: 'Credit Company Total Support',
            fda_of_variance: 'FDA of variance',
            fleet_of_variance: 'Fleet of variance',
            deduct_reason: '扣除原因',
            fda_file_name: 'FDA文件名',
        },
        enUS: {
            reconciliation_date: 'Reconciliation Date/File',
            credit_company: 'Credit Company',
            santander_total_amount: 'Credit Company Total Amount',
            MGBP_total_amount: 'MGBP Total Amount',
            amount_of_variance: 'Amount Of Variance',
            rebate_type: 'Rebate Type',
            reconciliation_status: 'Reconciliation Status',
            create_time: 'Create Time',
            start_time: 'Start Time',
            end_time: 'End Time',
            dealer_code: 'Dealer Code',
            dealer_name: 'Dealer Name',
            payment_type: 'Payment Type',
            status: 'Status',
            basic_price: 'Basic Price',
            paint: 'Paint',
            delivery: 'Delivery',
            total_price: 'Total Price',
            pda: 'FDA',
            credit_company_fleet: 'Credit Company Fleet',
            total_support: 'Total Support',
            credit_company_input_subsidy: 'Credit Company Input Subsidy',
            credit_company_input_fda: 'Credit Company Input FDA',
            credit_company_input_fleet: 'Credit Company Input Fleet',
            credit_company_total_support: 'Credit Company Total Support',
            fda_of_variance: 'FDA of variance',
            fleet_of_variance: 'Fleet of variance',
            total_of_variance: 'Total of variance',
            deduct_reason: 'Deduct Reason',
            fda_file_name: 'FDA File Name',
        },
    },
    salesTarget: {
        zhCN: {
            number_order: '序号',
            dealer_code: '经销商代码',
            dealer_name: '经销商名称',
            rebate_type: '返利类型',
            kpi_policy_no: 'Kpi政策号',
            group: '组',
            start_time: '开始时间',
            end_time: '结束时间',
            related_kpi_policy_name: '相关KPI政策名称',
            time: '时间',
            sales_target: '销售目标',
            original_sales_target: '原销售目标',
            actual_sales: '实际销量',
            month_achieving_rate: '月完成率',
            quarter_achieving_rate: '季度完成率',
            achievingRate: '完成率',
            kpi_level: 'KPI 级别',
        },
        enUS: {
            number_order: 'No.',
            dealer_code: 'Dealer Code',
            dealer_name: 'Dealer Name',
            rebate_type: 'Rebate Type',
            kpi_policy_no: 'Kpi Policy No.',
            group: 'Group',
            start_time: 'Start Time',
            end_time: 'End Time',
            related_kpi_policy_name: 'Related KPI Policy Name',
            time: 'Time',
            sales_target: 'Sales Target',
            original_sales_target: 'Original Sales Target',
            actual_sales: 'Actual Sales',
            month_achieving_rate: 'Month Achieving Rate',
            quarter_achieving_rate: 'Quarter Achieving Rate',
            achievingRate: 'Rate',
            kpi_level: 'KPI Level',
        },
    },
};
