import { createI18n } from 'vue-i18n';

import zhCN from '../../locales/zh-CN';
import enUS from '../../locales/en-US';
import { Locales } from '~/composables/i18n';
import { setDayjsLocale } from '~/composables/useDayjs';
import { commonRequests } from '~/requests/common/common.request';
import { resHandler } from '~/utils/http/handler';
import { useCommonStore } from '~/stores/common';

export const useStorageLocale = createGlobalState(() => {
    console.log(ref<Locales>(Locales.enUS), 'Locales.enUS')
    // const defaultLocale = ref<Locales>(Locales.enUS);
    const defaultLocale = useStorage('locale', ref<Locales>(Locales.enUS), localStorage, {
        deep: true,
        writeDefaults: true,
        listenToStorageChanges: true,
    }) as Ref<Locales>;
    return {
        defaultLocale,
    };
});

export const i18n = createI18n({
    legacy: false,
    messages: {
        [Locales.enUS]: enUS,
        [Locales.zhCN]: zhCN,
    },
    allowComposition: true,
});

export const { global: i18nGlobal } = i18n;

const i18nScope = effectScope();

export const initI18nScope = () => {
    i18nScope.run(() => {
        const { defaultLocale: locale } = useStorageLocale();
        const store = useCommonStore();
        console.log(locale, 'locale')
        i18nGlobal.locale.value = locale.value;

        setDayjsLocale(locale.value);

        watch(i18nGlobal.locale, async (val) => {
            console.log(val, 33333)
            store.locale = val;
            setDayjsLocale(val);
            const res = await commonRequests.switchLang();
            await resHandler(res);
            await store.reFetchRoutes();
        });
    });
};
